<template>
  <div class="payment pay">
    <van-nav-bar
      title="支付"
      left-text="返回"
      left-arrow
      @click-left="$router.go(-1)"
    />

    <div class="ctxPay">
      <div data-v-74bbaf8a="">
        <img data-v-74bbaf8a="" :src="payList.qrcode" alt="" />
      </div>
      <div class="pricePay">￥{{ parseFloat(payList.allFee) | fMoney }}</div>
    </div>
    <div class="context">
      <div class="collectPay">
        <div class="leftPay">收款方</div>
        <div class="rightPay">天堂电子商务有限公司</div>
      </div>
      <div class="collectPay aic">
        <div class="leftPay">剩余支付时间</div>
        <div class="rightPay">
          <van-count-down :time="time" format="mm 分:ss 秒" @finish="finish" />
        </div>
      </div>
    </div>
  </div>
</template> 

<script>
import $http from "@/utils/http";
export default {
  data() {
    return {
      payList: {},
      timer: null,
    };
  },
  created() {
    // 接收参数
    this.payList = this.$route.query;
    this.getPayResult();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  computed: {
    time() {
      //倒计时时间
      let allFee = 15 * 60 * 1000;
      //过去时间 = 当前时间 - 下单时间
      let passTime = Date.now() - this.payList.createTime;
      //剩余时间
      return allFee - passTime;
    },
  },
  methods: {
    //倒计时结束调用此方法
    finish() {
      this.$router.push("/");
    },
    //轮询获取支付结果
    getPayResult() {
      let params = { orderId: this.payList.orderId };
      this.timer = setInterval(() => {
        $http.get("/order/detail", { params }).then((res) => {
          if (res.result.orderStatus === "01") {
            clearInterval(this.timer);
            this.$router.push({
              path: "/order/list",
              query:params
            });
          }
        });
      }, 1000);
    },
  },
};
</script>



<style lang="less">
@import "./pay.less";
</style>